/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  getOrientation,
  resetOrientation,
  checkImage,
  getPermissionStatus,
  convertDateInTimezone,
  fetchApi
} from "../../../utils/methods";
import { saveChallengeData, createActivityChallengeV2, getActivityChallengeById } from "../../../redux/actions/challengeActions";
import {
  getUserCompanyDetails,
  allowChallengeEventForSpouseDependent,
} from "../../../redux/actions/userProfileActions";
import { TimeZone, imageErrorMessage } from "../../../utils/constants";
import { withTranslation } from "react-i18next";
import { getAllCompanies } from "../../../redux/actions";
import {
  Layout,
  ChallengeHeading,
  Container,
  InputContainer,
  StyledInputV2,
  StyledLabel,
  // UploadPhotoContainer,
  // RemoveImage,
  // InputImage,
  ButtonContainer,
  EditorContainer,
  MUICalendarWrapper,
  Border,
 
  // StyledLabelV2
} from "../../CreateEvent/style";
import StepsHeading from "../ActivityChallengeCreation/steps";
import RadioButton from "../../CustomRadioButton";
import { Main, ToolTip } from "../../AdminTeamCreation/styles";
import HoverButton from "../../common/HoverButtonV2";
import CommonButton from "../../common/CommonButton/CommonButton";
import { Arrows, CalendarIcon, closeIcon, CommonCloseIcon, DashIcon, LaunchEventIcon, NextArrows, SearchButtonIcon, UploadIcon, CalendarIconDisabled } from '../../../utils/icons';
import {CustomInput, SurvivorAverage, SurvivorChallengeSection, TitleContainer, FieldTitle, /*CustomTextArea*/} from '../ActivityChallengeCreation/styles';
// import moment from 'moment';
// import { challengeTierPoints } from '../../../../mockData.json';
import {
  Heading, CustomRadioButton, Locationwrapper, /*InputContainerV2, StyledDateTime*/
} from "../../CreateEvent/style";
// import {DateIconContainer} from '../../CreateEvent/styles' 
import { getCompanyLocation, getCompanyDepartment } from "../../../redux/actions";
import { GetTeams, GetCompanyTeamUsers, GetCompanyInfo, CreateChallenge } from '../../../redux/constants/apiConstants';
import { http } from '../../../redux/http';
import { ImageUrl } from "../../../utils/constants";
import { /*DateContainer*/ DateCalendarWrapper } from '../../ChallengeDetailsV2/StartChallengePopUp/styles';
// import CustomCalender from '../../common/CustomCalendarAdminChallenge/index';
import Waiting from '../../Waiting';
import { challengeTierPoints } from "../../../../mockData";
import CommonImageUploader from "../../common/CommonImageUploader/CommonImageUploader";
import CommonDropdown from "../../common/CommonDropDown/CommonDropdown";
import Editor from "../../CKEditor";
import { SearchFilter1, SearchIcon } from "../../PeopleHomeV2/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
class LaunchActivityChallenge extends Component {

  constructor(props) {
    super();
    const { survivorChallengeData, isGroupChallenge, isSurvivorChallenge, createLibrary } = props;
    let ImageName = '';
    if (!_.isEmpty(survivorChallengeData) && (props.activityChallengeById || props.editLibrary)) {
      const Array = survivorChallengeData.image.split('/');
      ImageName = Array[Array.length - 1];
    }
    let updatedTiers = challengeTierPoints;
    this.state = {
      title: _.get(survivorChallengeData, 'title', ''),
      date: dayjs(_.get(survivorChallengeData, 'date', new Date())),
      endDate: null,
      // endDate: dayjs(_.get(survivorChallengeData, 'endDate', new Date())),
      launchEndDate:null,
      scheduleStartDate: dayjs(new Date()),
      scheduleEndDate: null,
      description: _.get(survivorChallengeData, 'body', ''),
      imageName: ImageName,
      time: _.get(survivorChallengeData, 'time', dayjs().format('hh:mm a')),
      endTime: _.get(survivorChallengeData, 'endTime', dayjs().format('hh:mm a')),
      showDateTimeInput: '',
      activityType: isGroupChallenge ? survivorChallengeData.event_type === 'Total Steps' ? 'Total Group Steps' : survivorChallengeData.event_type === 'Total Calories' ? 'Total Group Calories' : 'Total Group Distance' : _.get(survivorChallengeData, 'event_type', 'Total Distance'),
      challengePointValue: _.get(survivorChallengeData, 'challenge_point', '0'),
      challengeMode: createLibrary ? 'Solo' : _.get(survivorChallengeData, 'challenge_mode', ''),
      weeklyIncrement: _.get(survivorChallengeData, 'weekly_increment', 0),
      survivorAverage: _.get(survivorChallengeData, 'survivor_average', 0),
      amigosToInvite: [],
      openDateTimePicker: '',
      imgSrc: ImageUrl+"/"+_.get(survivorChallengeData, 'image', ''),
      editImage:_.get(survivorChallengeData, 'image', ''),
      companies: _.get(survivorChallengeData, 'companies', []),
      location: [],
      isSpouse: false,
      usersToInvite:0,
      isDependent: false,
      isEmployee: false,
      challengePoints: updatedTiers,
      activityChallengeType: createLibrary ? "Classic challenge" : survivorChallengeData.challenge_type === 'new_group' ? 'Group Challenge' : survivorChallengeData.challenge_type === 'group' ? 'Classic challenge' : 'Survivor challenge',
      survivorChallenge: isSurvivorChallenge,
      groupChallenge: isGroupChallenge,
      imageUpdated: 0,
      challengePointKey: _.get(survivorChallengeData, 'challenge_point', '0')+" "+"Points",
      emailOption: {
        "launch_challenge_email": 1,
        "challenge_reminder_email": 1,
        "challenge_complete_email": 1
      },
      companiesPermission: false,
      locationPermission: false,
      selectedOption: 1,
      surveyId: '',
      surveyType: '',
      dailyGoal: null,
      selectedDailyGoalOption: 1,
      is_team_challenge:0,
      teams:[],
      allTeams:[],
      selectedCompany:{
        "id": 1,
        "company_name": "Woliba",
        "show_biometric": 1,
        "spouse_or_dependent": 1,
        "company_start_date": 2016,
        "show_events": 1,
        "show_education": 1,
        "show_employee_directory": 1 },

      step: 1,
      activityArrow: false,
      challengeType: false,
      pointsArrow: false,
      buttonClickScrollTop: false,
      menuIsOpen: true,
      selectedCompanyCheckBox: 0,
      selectedCompanyValue:null,
      selectedCompanyName: null,
      // showLocation: 5,
      city_location: [],
      scheduleChallenge: null,
      buttonStatus: false,
      launchEndDateActive: false,
      scheduleStartDateActive: false,
      scheduleEndDateActive: false,
      placeholder:null,
      startDatePlaceholder:null,
      showLoader: false,
      challengeLength: null,
      weekArrow: false,
      selectUser: 0,
      city_department: [],
      companyInfo:null,
      searchTerm:'',
      filteredCompanies:[],
    }
  }

  componentDidMount() {
    this.getTeams(this.props.companyDetails.id);
    this.props.activityChallengeById || this.props.editLibrary ? this.fillProps() : null;
    const companiesPermission = getPermissionStatus("Show companies in challenge", this.props.userPermissions);
    const locationPermission = getPermissionStatus("Show locations in challenge", this.props.userPermissions);
    // this.props.getSurveyLibraries();
    // if (companiesPermission) {
  
    // } else {
    this.props.fetchCompanyForUser();
    this.props.allowChallengeEventForSpouseDependent();
    if(this.props.role!="ADMIN"){
      this.setState({
        companies: this.props.userCompany && [this.props.userCompany.id]
      })}
    this.setState({
      companiesPermission: companiesPermission,
      locationPermission: locationPermission
    })
    document.addEventListener('mousedown', this.handleClick);

    if(this.props.role != "ADMIN"){
      this.getTeams(this.props.companyDetails.id);
    }else{
      this.onCompanySelect('selectedCompany', this.state.selectedCompany, this.state.selectedCompany['key'])
    }
    this.getCompanyInfo(this.props.companyDetails.id)

    if (companiesPermission) {
      if (!this.props.allCompanies) {
        this.props.fetchAllCompanies();
      }else{
        this.setState({filteredCompanies:this.props.allCompanies})
        
      }
    } 
  }

  componentDidUpdate(prevProps) {
    if (this.props.survivorChallengeData !== prevProps.survivorChallengeData || prevProps.surveyLibraries !== this.props.surveyLibraries) {
      this.props.activityChallengeById || this.props.editLibrary ? this.fillProps() : null;
    }
    // if (!this.state.companiesPermission && this.props.userCompany !== prevProps.userCompany) {
    //   this.setState({
    //     companies: this.props.userCompany && [this.props.userCompany.id]
    //   })
    // }
    if(prevProps.allCompanies != this.props.allCompanies){
      this.setState({filteredCompanies:this.props.allCompanies})
    }
    
    

  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  onCompanySelect = async(name, value, key = '') => {
    const { challengePointKey } = this.state;
    const AuthToken = localStorage.getItem('AUTH_TOKEN');
    try {
      const res = await http.get(`${GetCompanyTeamUsers}/${value.id}`, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        this.setState({
          allTeams:res,
          // [name]: value,
          challengePointKey: key != '' ? key : challengePointKey,
          // companies:[value.id]
        })
      }
    }catch(error){
      toast.error(error.message);
    }
    
  };

  fillProps = () => {
    const { survivorChallengeData, isGroupChallenge, isSurvivorChallenge, surveyLibraries } = this.props;
    const Array = survivorChallengeData.image.split('/');
    let ImageName = Array[Array.length - 1];
    let tierPointsIndex = this.state.challengePoints.findIndex((data) => data.value == _.get(survivorChallengeData, 'challenge_point', '0'));
    let surveyIndex = surveyLibraries && surveyLibraries.length > 0 ? surveyLibraries.findIndex((data) => data.id === survivorChallengeData.survey_id) : -1;
    this.setState({
      title: _.get(survivorChallengeData, 'title'),
      date: dayjs(_.get(survivorChallengeData, 'date', new Date())),
      endDate: null,
      // endDate: dayjs(_.get(survivorChallengeData, 'endDate', new Date())),
      description: _.get(survivorChallengeData, 'body'),
      activityType: isGroupChallenge ? survivorChallengeData.event_type === 'Total Steps' ? 'Total Group Steps' : survivorChallengeData.event_type === 'Total Calories' ? 'Total Group Calories' : 'Total Group Distance' : _.get(survivorChallengeData, 'event_type', 'Total Distance'),
      challengePointValue: tierPointsIndex == -1 ? 0 : _.get(survivorChallengeData, 'challenge_point', '12'),
      challengeMode: _.get(survivorChallengeData, 'challenge_mode') || 'Solo',
      weeklyIncrement: _.get(survivorChallengeData, 'weekly_increment', 0) || 0,
      survivorAverage: _.get(survivorChallengeData, 'survivor_average', 0) || 0,
      imgSrc: ImageUrl+"/"+_.get(survivorChallengeData, 'image', ''),
      editImage:_.get(survivorChallengeData, 'image', ''),
      imageName: ImageName,
      survivorChallenge: isSurvivorChallenge,
      groupChallenge: isGroupChallenge,
      activityChallengeType: survivorChallengeData.challenge_type === 'new_group' ? 'Group Challenge' : survivorChallengeData.challenge_type === 'group' ? 'Classic challenge' : 'Survivor challenge',
      challengePointKey: this.state.challengePointValue+" "+"Points",
      surveyId: survivorChallengeData.survey_id,
      surveyType: surveyLibraries && surveyLibraries.length > 0 ? surveyLibraries[surveyIndex === -1 ? 0 : surveyIndex].survey_name : '',
      selectedOption: surveyIndex === -1 ? 1 : 0,
      dailyGoal: null,
      // selectedDailyGoalOption: this.state.dailyGoal ? 0 : 1
    })
  }

  activityChallengeTypes = [
    {"key": "Classic challenge", "value": 'Classic challenge'},
    {"key": "Survivor challenge", "value": 'Survivor challenge'},
    {"key": "Group Challenge", "value": 'Group Challenge'}
  ]

  activityTypesItems= [
    {"key": "Total Distance", "value": 'Total Distance'},
    {"key": "Total Steps", "value": 'Total Steps'},
    {"key": "Total Calories", "value": 'Total Calories'}
  ]

  groupActivityTypesItems= [
    {"key": "Total Group Distance", "value": 'Total Group Distance'},
    {"key": "Total Group Steps", "value": 'Total Group Steps'},
    {"key": "Total Group Calories", "value": 'Total Group Calories'}
  ]

  stepsHeadingEvent = [
    {
      name: 'About Challenge',
      showArrow: true,
      number: 1
    },
    {
      name: 'Customize Challenge',
      showArrow: true,
      number: 2
    },
    {
      name: 'Invite Users',
      showArrow: true,
      number: 3
    },
    {
      name: 'Launch Challenge',
      showArrow: false,
      number: 4
    }
  ]; 

  renderHeading = () => {
    const { survivorChallengeData } = this.props;
    const challengeType= survivorChallengeData.challenge_type=="survivor"?"Survivor Challenge":survivorChallengeData.challenge_type=="group"&&survivorChallengeData.is_team_challenge?this.props.t("Team Challenge"):survivorChallengeData.challenge_type=="new_group"?this.props.t("Group Challenge"):this.props.t("Classic Challenge")
    return(
      <ChallengeHeading color="#005C87">
        <ToolTip
          margin={"auto 15px auto 0"}
          activeValue={"transparent"}
          height="40px"
          width="55px"
        >
          <HoverButton
            title="Close"
            width="24px"
            height="24px"
            svgPath={CommonCloseIcon()} 
            onClick={() => window.history.back() }
            // backgroundColorActive="#007AB1"
            // backgroundColor="#005C871A"
            activeColor={"#007AB1"}
            fillOpacity={1}
            backgroundColor="#005C871A"
          />
        </ToolTip>
        {this.props.t("Launch the")} {this.props.t(challengeType)}
      </ChallengeHeading>
    )};

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChange = (e) => {
    let file = e.target.files[0];
    if (checkImage(e.target.files)) {
      if ((file?.size / 1000000) <= 20) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        const array = document.getElementById('create-challenge-file').value.split("\\");
        reader.onloadend = () => {
          this.setState({
            imgSrc: reader.result,
            imageName: array[array.length - 1],
            editImage: reader.result
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.setState({
                imgSrc: baseImage,
                editImage: baseImage,
                imageUpdated: this.props.editLibrary ? 1 : 0
              });
            });
          });
        };
      } else {
        toast.error('Please select image file less than 20MB');
        document.getElementById('create-challenge-file').value = '';
      }
    } else {
      toast.error(imageErrorMessage);
      document.getElementById('create-challenge-file').value = '';
    }
  };

  onChangeDate = (value) => {
    let dateStr = value;
    let parts = dateStr.split('-');
    let formattedDate = parts[2] + '-' + parts[0] + '-' + parts[1];
    this.setState({
      endDate: dayjs(new Date(convertDateInTimezone(formattedDate))),
      placeholder: dayjs(new Date(convertDateInTimezone(formattedDate))),
      buttonStatus:false
    })
  }

  hideCalendar = () => {
    this.setState({
      launchEndDateActive: false,
    });
  };

  showCalendar = (date) => {
    if (date === "launchEndDateActive") {
      this.setState({
        launchEndDateActive: true,});
    } else if (date === "scheduleStartDateActive") {
      this.setState({
        scheduleStartDateActive: true,
        scheduleEndDateActive: false,
      });
    } else {
      this.setState({
        scheduleEndDateActive: true,
        scheduleStartDateActive: false,
      });
    }
  };


  onChangeScheduleDate = (value) => {
    let dateStr = value;
    let parts = dateStr.split('-');
    let formattedDate = parts[2] + '-' + parts[0] + '-' + parts[1];
    this.setState({
      date: dayjs(new Date(convertDateInTimezone(formattedDate))),
      endDate: dayjs(new Date(convertDateInTimezone(formattedDate))),
      startDatePlaceholder: dayjs(new Date(convertDateInTimezone(formattedDate))),
      buttonStatus:false,
      scheduleEndDate: dayjs(new Date(convertDateInTimezone(formattedDate)))
    })
  }

  onChangeDateV1 = (date, stateName) => {
    this.setState({
      [stateName]: dayjs(date),
      // openDateTimePicker: stateName,
    });
  };

  
  onImageChange = (imageSrc, imageName, editImage) => {
    this.setState({
      imgSrc: imageSrc,
      imageName: imageName,
      editImage: editImage,
    });
  };
  
  removePhoto = () => {
    this.setState({ imgSrc: null, editImage: null });
  };
  

  onChangeDescription = (e) => {
    const newContent = e.getData();
    const trimmedString = newContent.replace(/\s|&nbsp;/g, "");
    trimmedString.length === 0 ||
    trimmedString.length === 7 ||
    trimmedString == "<p></p><p></p>"
      ? this.setState({ description: "" })
      : this.setState({ description: newContent });
  };

  getTeams = async(companyId)=>{
    const AuthToken = localStorage.getItem('AUTH_TOKEN');
    try {
      const res = await http.get(`${GetTeams}/${companyId}`, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        this.setState({
          allTeams:res
        })
      }
    }catch(error){
      toast.error(error.message);
    }
  }

  stepOneForm = () => {
    const {
      title,
      description,
      step,
    } = this.state;
    const { t } = this.props;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container height="100%" stepBackground="#005C87" color="#005C87" paddingForm>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} heading={this.stepsHeadingEvent}/>
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">{this.props.t("Challenge Introduction")}</div>
              </div>
              <div className="formBody">
                <InputContainer>
                  <StyledLabel color="#005C87">{this.props.t("Title of the challenge")}{<span className="imp">*</span>}</StyledLabel>
                  <StyledInputV2
                    placeholder={t("Write title here...")}
                    name="title"
                    onChange={this.onChangeInput}
                    value={title}
                    maxLength="100"
                    isPadding={" 0px 20px 0px 12px"}
                  />
                </InputContainer>
                <StyledLabel color="#005C87">{this.props.t("Challenge image")}{<span className="imp">*</span>}</StyledLabel>
                <CommonImageUploader
                  imgSrc={this.state.imgSrc}
                  t={t}
                  closeIcon={closeIcon}
                  UploadIcon={UploadIcon}
                  onImageChange={this.onImageChange}
                  onRemoveImage={this.removePhoto}
                />            
                <InputContainer>
                  <StyledLabel style={{marginTop:"20px"}} color={"#005c87"}>{this.props.t("About the challenge")}{<span>*</span>}</StyledLabel>
                  <EditorContainer>
                    <Editor
                      content={description}
                      onChange={this.onChangeDescription}
                    />
                  </EditorContainer>
                </InputContainer>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  onSelect = (name, value, key) => {
    // const { challengePointKey } = this.state;
    (name === "activityChallengeType") ?
      this.setState({
        [name]: value,
        survivorChallenge: value === "Survivor challenge",
        groupChallenge: value === "Group Challenge",
        activityType: value === "Group Challenge" ? "Total Group Distance" : "Total Distance",
        challengePointKey: this.state.challengePointKey,
        activityChallengeValue: key
      }) :
      this.setState({
        [name]: value,
        // challengePointKey: key != '' ? key : challengePointKey
      });
  };
  
  handleTypeofActivity = (name) => {
    this.setState({
      activityType: name.value,
    })
   
  };

  onSelectWeeks = (value) => {
    this.setState({
      challengeLength: value  })
  };

  onSelectCompany = (name, value, key ) => {
    this.setState({
      [name]: value,
      selectedCompanyName: key,
      companies: [value],
      // showLocation:5,
      usersToInvite:0,
      isSpouse:false,
      isDependent:false,
      teams:[],
      city_location :[],
      city_department: [],
    });
    this.props.getCompanyLocation(value);
    this.props.getCompanyDepartment(value);
    this.getTeams(value);
    this.getCompanyInfo(value)
  };

  getCompanyInfo = async(companyId)=>{
    let apiUrl = GetCompanyInfo + "/" + companyId;
    try {
      const res = await fetchApi(apiUrl , "GET");
      if (res.status === "success") {
        this.setState({companyInfo: res?.data?.companyInfo?.[0]})

      } else {
        toast.error(res.error);
      }
    } catch (error) {
      window.console.log(error);
    }
  }

  isNumberKey = (evt) => {
    let charCode = (evt.which) ? evt.which : event.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) evt.preventDefault();
  };

  toggleRadioGoalButton = (value) => {
    this.setState({   usersToInvite: parseInt(value,10) }, () => { });
    if(value===1){
      this.setState({
        teams:[]
      })
    }
    else{
      this.setState({
        city_location:[],
        city_department: []
      })
    }
  };



  onSelectPoints = (name) => {
    this.setState({
      challengePointValue: name.value,
      challengePointKey: name.key
    });
  };

  stepTwoForm = () => {
    const {
      step,
      groupChallenge,
      activityType,
      survivorChallenge,
      survivorAverage,
      weeklyIncrement,
      challengePoints,
      pointsArrow,
      challengePointKey,
      challengePointValue,
      selectedDailyGoalOption,
      dailyGoal,
      activityArrow
    } = this.state;
    const { t, survivorChallengeData } = this.props;
 
    const activityTypes = groupChallenge ? this.groupActivityTypesItems : this.activityTypesItems;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container height="100%" stepBackground="#005C87" color="#005C87" paddingForm>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} heading={this.stepsHeadingEvent}/>
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">{this.props.t("How would you like to customize your challenge?")}</div>
              </div>
              <div className="formBody">
                <TitleContainer widthValue="70%" height="auto" padding="0px">
                  <CommonDropdown
                    tileContainerStyle={{ width: "100%", padding: "0px 0px 0px 0px" }}
                    dropdownStyle={{ top: "90px" }}
                    labelText={t("Type of activity")}
                    isRequired={true}
                    dropdownOpen={activityArrow}
                    title={activityType ? this.props.t(activityType) : this.props.t("Select activity type")}
                    placeholder={this.props.t("Select activity type")}
                    onClick={() => this.setState({ activityArrow: !this.state.activityArrow })}
                    data={activityTypes}
                    // onSelect={(index, value) => this.onSelect('activityType', value, activityTypes[index].key)}
                    onSelect={this.handleTypeofActivity}
                    onSelectParameter={['value', 'key']}
                    active={activityType}
                    itemValue={true}
                    valueIndex={0}
                    t={this.props.t}
                  />
                  <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px'}}/>
                </TitleContainer>
               

                {(groupChallenge) &&
                  <SurvivorAverage  isMargin={"20px"} width="100%" isHeight={"50px"}>
                    <FieldTitle marginTop={"20px"}>{t('Group goal')}</FieldTitle>
                    <CustomInput
                      isHeight={"50px"}
                      isFont={"rubik"}
                      isRadius={"4px"}
                      isBorder={"1px solid rgba(0, 122, 177, 0.30)"}
                      isBgColor={"white"}
                      type='number'
                      name='survivorAverage'
                      onChange={this.onChangeInput}
                      value={activityType.includes('Distance') ? parseFloat(survivorAverage) : parseInt(survivorAverage,10)}
                      pattern='[0-9]*'
                      min="0"
                      onKeyPress={(e) => this.isNumberKey(e)}
                      placeholder={activityType.includes("Steps")?this.props.t("Enter E.g. 500 Steps"):activityType.includes("Calories")?this.props.t("Enter E.g. 30 Calories"):this.props.t("Enter E.g. 25 Miles")}
                    />
                    <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px'}}/>
                  </SurvivorAverage>}
                <SurvivorChallengeSection>
                  {(survivorChallenge) && <SurvivorAverage  isMargin={"20px"}  isHeight={"50px"}>
                    <FieldTitle>{groupChallenge ? t('Team Goal') : t('Week 1 Goal')}</FieldTitle>
                    <CustomInput
                      isHeight={"50px"}
                      isFont={"rubik"}
                      isRadius={"4px"}
                      isBorder={"1px solid rgba(0, 122, 177, 0.30)"}
                      isBgColor={"white"}
                      type='number'
                      name='survivorAverage'
                      onChange={this.onChangeInput}
                      value={activityType.includes('Distance') ? parseFloat(survivorAverage) : parseInt(survivorAverage,10)}
                      pattern='[0-9]*'
                      min="0"
                      onKeyPress={(e) => this.isNumberKey(e)}
                      placeholder={activityType==="Total Distance"?this.props.t("Enter E.g. 25,000 Miles"):activityType==="Total Calories"?this.props.t("Enter E.g. 300 Calories"):this.props.t("Enter E.g. 5000 Steps")}
                    />
                  </SurvivorAverage>}
                  {survivorChallenge &&
              <SurvivorAverage  isMargin={"20px"} isHeight={"50px"}>
                <FieldTitle>{t("Weekly increment")}</FieldTitle>
                <CustomInput
                  isHeight={"50px"}
                  isFont={"rubik"}
                  isRadius={"4px"}
                  isBorder={"1px solid rgba(0, 122, 177, 0.30)"}
                  isBgColor={"white"}
                  type='number'
                  name="weeklyIncrement"
                  value={parseFloat(weeklyIncrement)}
                  onChange={this.onChangeInput}
                  pattern='[0-9]*'
                  min="0"
                  onKeyPress={(e) => this.isNumberKey(e)}
                  placeholder={activityType==="Total Steps"?this.props.t("Enter E.g. 500 Steps"):activityType==="Total Calories"?this.props.t("Enter E.g. 30 Calories"):this.props.t("Enter E.g. 25 Miles")}
                />
              </SurvivorAverage>}
                 
                </SurvivorChallengeSection>
                { survivorChallenge && <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px'}}/>}

                <TitleContainer widthValue="70%" height="auto" padding="0px">
                  <CommonDropdown
                    tileContainerStyle={{ width: "100%", padding: "20px 0px 0px 0px" }}
                    dropdownStyle={{ top: "90px" }}
                    labelText={t("Challenge point value")}
                    isRequired={true}
                    dropdownOpen={pointsArrow}
                    placeholder={t("Select point palue")}
                    title={challengePointKey=="0 Points"?"No Points":challengePointKey?t(challengePointKey) : t("Select point value")}
                    id="dropdown-recurring"
                    onClick={() => this.setState({ pointsArrow: !pointsArrow })}
                    data={challengePoints}
                    onSelect={this.onSelectPoints}
                    active={challengePointValue}
                    onSelectParameter={["value", "key"]}
                    itemValue={true}
                    valueIndex={1}
                    labelMargin={"0px"}
                    t={t}
                  />
                  {(!groupChallenge && !survivorChallenge) && <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px'}}/>}
                </TitleContainer>

             
                {survivorChallengeData.challenge_type === 'group'?<StyledLabel color="#005C87">
                  {this.props.t("Would you like to set a daily goal")} ?{<span className="imp">*</span>}
                </StyledLabel>:null}
                {survivorChallengeData.challenge_type === 'group'?<div className="radioButtonDiv">
                  <span onClick={() => this.toggleRadioButton("selectedDailyGoalOption",0)}>
                    <RadioButton
                      id="1"
                      handler={this.toggleRadioButton}
                      value={0}
                      isChecked={selectedDailyGoalOption === 0}
                      label={this.props.t("Set Goal")}
                      challengeCreate={true}
                    />
                  </span>
                  <span onClick={() => this.toggleRadioButton("selectedDailyGoalOption",1)}>
                    <RadioButton
                      id="2"
                      handler={this.toggleRadioButton}
                      value={1}
                      isChecked={selectedDailyGoalOption === 1}
                      label={this.props.t("No")}
                      challengeCreate={true}
                    />
                  </span>
                </div>:null}
                {selectedDailyGoalOption===0 &&
                <SurvivorAverage width="100%" isHeight={"50px"}>
                  <FieldTitle>{t("Individual daily goal")}</FieldTitle>
                  <CustomInput
                    isFont={"rubik"}
                    isRadius={"4px"}
                    isBorder={"1px solid rgba(0, 122, 177, 0.30)"}
                    isBgColor={"white"}
                    type='number'
                    name='dailyGoal'
                    onChange={this.onChangeInput}
                    value={dailyGoal}
                    maxLength="10"
                    placeholder={activityType==="Total Distance"?this.props.t("Enter E.g. 5 Daily Miles"):activityType==="Total Calories"?this.props.t("Enter E.g. 300 Daily Calories Burn"):this.props.t("Enter E.g. 5000 Daily Steps")}
                  />
                </SurvivorAverage>}
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  companyToggleButton = (e) => {
    this.setState({
      selectedCompanyCheckBox: e,
      companies: [],
      selectedCompanyValue: "",
    });
    if (e === 0) {
      this.setState({
        city_location: [],
      });
    }
  };

  toggleRadioButton = (name, value) => {
    this.setState({ [name]: value }, () => { });
    if(name=="selectedCompanyCheckBox"&&value===1){
      this.setState({
        companies:[],
        selectedCompanyName: null,
        selectedCompanyValue:null
      })
    }
    if(name=="selectedCompanyCheckBox"&&value===0){
      this.setState({
        // showLocation:5,
        usersToInvite:0,
        teams:[],
        city_location :[],
        city_department: [],
        isSpouse:false,
        isDependent:false,
        companies:[],
      })
    }
  };

  dateRadioButton = (name, value ) => {
    this.setState({ [name]: value });
    if(value===1){
      this.setState({
        date: dayjs(),
        // endDate: dayjs(),
        endDate: null,
        placeholder: null,
        startDatePlaceholder: null,
        buttonStatus:false,
        challengeLength: null
      })
    }
    if(value===0){
      this.setState({
        date: dayjs(),
        placeholder: null,
        startDatePlaceholder: null,
        buttonStatus:false,
        challengeLength:null,
        endDate: null,
      })
    }
  };

  onSelectSurveyType = (name, value, id) => {
    this.setState({
      [name]: value,
      surveyId: id
    });
  };

  selectAllCompanies = (e) => {
    e.preventDefault();
    let invitedCompanies = [];
    const { allCompanies } = this.props;
    if (this.state.companies.length === allCompanies.length) {
      invitedCompanies = [];
    } else {
      allCompanies && allCompanies.map((company) => {
        invitedCompanies.push(company.id);
      });
    }
    this.setState({
      companies: invitedCompanies
    })
  };


  

  inviteToggleButton = (e) => {
    this.setState({
      usersToInvite: e,
      // companies: [],
      // selectedCompanyValue: "",
    });
    if (e === 0) {
      this.setState({
        // city_location: [],
      });
    }
  };
  

  onCheckboxChange = (element) => {
    const { companies } = this.state;
    if (companies.includes(element)) {
      let index = companies.findIndex((item) => item === element);
      if (index > -1) {
        companies.splice(index, 1);
      }
    } else {
      companies.push(element);
    }
    this.setState({
      companies: companies
    })
  };

  onSelectLocation = (name, /*value, id*/) => {
    this.setState({
      [name]: name.company_name,
      companies: [name.id],
      isEmployee:false,
      isDependent:false,
      isSpouse:false,
      selectedCompanyValue: name.company_name,
    });
    
    this.props.getCompanyLocation(name.id);
    this.props.getCompanyDepartment(name.id);
    this.getTeams(name.id);
    this.getCompanyInfo(name.id);
  };

  selectAllLocation = (e, locationDetails) => {
    e.preventDefault();
    const { city_location } = this.state;
    if (city_location.length == locationDetails.length) {
      this.setState({
        city_location: [],
      });
    } else {
      let arr = [];
      for (let i = 0; i < locationDetails.length; i++) {
        arr.push(locationDetails[i].id);
      }
      this.setState({
        city_location: arr,
      });
    }
  };

  selectAllDepartment = (e, departmentDetails) => {
    e.preventDefault();
    const { city_department } = this.state;
    if (city_department.length == departmentDetails.length) {
      this.setState({
        city_department: [],
      });
    } else {
      let arr = [];
      for (let i = 0; i < departmentDetails.length; i++) {
        arr.push(departmentDetails[i].id);
      }
      this.setState({
        city_department: arr,
      });
    }
  };

  onChangeLocation = (id) => {
    const { city_location } = this.state;
    if (city_location.includes(id)) {
      let index = city_location.findIndex((item) => item === id);
      if (index > -1) {
        city_location.splice(index, 1);
      }
    } else {
      city_location.push(id);
    }
    this.setState({
      city_location: city_location,
    });
  };

  onChangeDepartment = (id) => {
    const { city_department } = this.state;
    if (city_department.includes(id)) {
      let index = city_department.findIndex((item) => item === id);
      if (index > -1) {
        city_department.splice(index, 1);
      }
    } else {
      city_department.push(id);
    }
    this.setState({
      city_department: city_department,
    });
  };

  removeCommaFromFirstPlace = (str) => {
    if (str.charAt(0) === ",") {
      return str.substring(1);
    }
    return str;
  };

  handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    this.setState({ searchTerm: value });

    const filtered = this.props.allCompanies.filter((company) =>
      company.company_name.toLowerCase().includes(value)
    );

    this.setState({ filteredCompanies: filtered });
  };

  onChangeTeam = (id) => {
    const { teams } = this.state;
    if (teams.includes(id)) {
      let index = teams.findIndex((item) => item === id);
      if (index > -1) {
        teams.splice(index, 1);
      }
    } else {
      teams.push(id);
    }
    this.setState({
      teams: teams,
    });
  };


  selectAllTeam = (e, teamDetails)=>{
    e.preventDefault();
    const { teams } = this.state;
    if (teams.length == teamDetails.length) {
      this.setState({
        teams: [],
      });
    } else {
      let arr = [];
      for (let i = 0; i < teamDetails.length; i++) {
        arr.push(teamDetails[i].id);
      }
      this.setState({
        teams: arr,
      });
    }

  }

  onTeamsCheckboxChange = (element) => {
    const { teams } = this.state;
    if (teams.includes(element)) {
      let index = teams.findIndex((item) => item === element);
      if (index > -1) {
        teams.splice(index, 1);
      }
    } else {
      teams.push(element);
    }
    this.setState({
      teams: teams
    })
  };

  selectSpouseDependent = (stateName) => {
    this.setState((prev) => ({
      [stateName]: !prev[stateName],
    }));
  };


  stepThreeForm = () => {
    const {
      companies,
      city_location,
      companiesPermission,
      step,
      selectedCompanyValue,
      selectedCompanyCheckBox,
      isEmployee,
      isDependent,
      isSpouse,
      usersToInvite,
      allTeams,
      filteredCompanies,
      // departments,
      city_department,
      teams,
      companyInfo
    } = this.state;
    const { allCompanies, locationDetails, t, role, departmentDetails } = this.props;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container color={"#005c87"}>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} heading={this.stepsHeadingEvent}/>
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">{this.props.t("Who would you like to invite?")}</div>
              </div>
              <div className="formBody">
                {companiesPermission && (
                  <StyledLabel color="#005c87">
                    {this.props.t("Host your challenge for?")}{<span>*</span>}
                  </StyledLabel>
                )}
                {companiesPermission && (
                  <div className="radioButtonDiv">
                    <span onClick={() => this.companyToggleButton(0)}>
                      <RadioButton
                        id="global"
                        handler={this.companyToggleButton}
                        value={0}
                        isChecked={selectedCompanyCheckBox === 0}
                        label={t("Global")}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                      />
                    </span>
                    <span onClick={() => this.companyToggleButton(1)}>
                      <RadioButton
                        id="company"
                        handler={this.companyToggleButton}
                        value={1}
                        isChecked={selectedCompanyCheckBox === 1}
                        label={t("For an Individual Company")}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                      />
                    </span>
                  </div>
                )}

                {companiesPermission ? (<>

                  <Heading>
                    <div className="name" style={{color:"#005c87"}}>{this.props.t(`Select companies to invite`)} &nbsp; <span> { companies.length > 0 ? "(" + companies.length + " Companies Selected)" : ""}</span></div>
                    <SearchFilter1 style={{width:"416px",maxWidth:"416px"}}>
                      <StyledInputV2
                        type="text" placeholder={t("Search company")}
                        value={this.state.searchTerm} 
                        onChange={this.handleSearch} 
                        // onKeyDown={this.handleKeyDown}
                        style={{border:"none"}}
                        bordercolor="white"
                        isPadding={" 0px 20px 0px 12px"}
                      />
                      <SearchIcon> {SearchButtonIcon()}</SearchIcon>
                    </SearchFilter1>
                  </Heading>
    
                  <Locationwrapper background="white" isFlex={'1 1 50%'}>
                    {selectedCompanyCheckBox === 0 ? <div className="checkBoxWidth">
                      <span style={{color:"#005c87"}}>
                        <CustomRadioButton
                          onClick={(e) =>
                            this.selectAllCompanies(e, allCompanies)
                          }
                          
                          background={"#85c0ea"}
                        >
                          {" "}
                          {allCompanies &&
                            companies.length === allCompanies.length && (
                            <div></div>
                          )}{" "}
                        </CustomRadioButton>{" "}
                        {this.props.t("All Companies")}
                      </span>
                    </div>: null} 
                    {filteredCompanies && filteredCompanies.length > 0
                      ? filteredCompanies.map((company, index) => (
                        <div className="checkBoxWidth" key={index}>
                          <span style={{color:"#005c87"}}>
                            <CustomRadioButton
                              onClick={() =>{
                                selectedCompanyCheckBox === 0 ? this.onCheckboxChange(company.id,company.company_name) : selectedCompanyCheckBox === 1 ? this.onSelectLocation(company) : null
                              }
                              }
                              background="#afcbd3"
                              style={{border:"1px solid #649bb3"}}
                              id={company.id + "1"}
                            >
                              {" "}
                              {companies.includes(company.id) && (
                                <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                              )}{" "}
                            </CustomRadioButton>
                            {company.company_name}
                          </span>
                        </div>
                      )): null}
                  </Locationwrapper>


                </>) : null}


                {role === "ADMIN" && (<Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>)}

                {companies?.length > 0 ? (<><div className="name" style={{color:"#005c87",marginTop: "20px",marginBottom:"16px"}} >
                  {this.props.t("Select users")}
                  <span style={{color:" #F4AAA9"}}>*</span>
                </div>
                <div className="radioButtonDiv" style={{zIndex:"10",position:"relative"}}>
                  <span onClick={() => this.inviteToggleButton(0)}>
                    <RadioButton
                      id="all-users"
                      handler={this.inviteToggleButton}
                      value={0}
                      isChecked={usersToInvite === 0}
                      label={t("All Users")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                    />
                  </span>
                  {(role != "ADMIN" || selectedCompanyCheckBox === 1) && departmentDetails?.length > 0 &&(<span onClick={() => this.inviteToggleButton(1)}>
                    <RadioButton
                      id="departments"
                      handler={this.inviteToggleButton}
                      value={1}
                      isChecked={usersToInvite === 1}
                      label={t("Departments")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                    />
                  </span>)}
                  {(role != "ADMIN" || selectedCompanyCheckBox === 1) && locationDetails?.length > 0 &&(<span onClick={() => this.inviteToggleButton(2)}>
                    <RadioButton
                      id="locations"
                      handler={this.inviteToggleButton}
                      value={2}
                      isChecked={usersToInvite === 2}
                      label={t("Locations")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                    />
                  </span>)}
                  {(role != "ADMIN" || selectedCompanyCheckBox === 1) && allTeams.length > 0 &&( <span onClick={() => this.inviteToggleButton(3)}>
                    <RadioButton
                      id="teams"
                      handler={this.inviteToggleButton}
                      value={3}
                      isChecked={usersToInvite === 3}
                      label={t("Teams")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                    />
                  </span>)}
                </div></>):null}
                {(role === "ADMIN" &&
                  locationDetails && 
                  locationDetails?.length > 0 &&
                  selectedCompanyCheckBox === 1 &&
                  selectedCompanyValue.length > 0 && usersToInvite === 2) ||
                (locationDetails &&
                  locationDetails?.length > 0 &&
                  role != "ADMIN"  && usersToInvite === 2 ) ? (<Heading>
                    <div className="name" style={{color:"#005c87"}}>
                      {this.props.t("Select locations")}
                      <span className="astric">*</span>
                    </div>
                  </Heading>
                  ) : null}
                {(role === "ADMIN" &&
                usersToInvite === 2 && locationDetails &&
                  locationDetails.length > 0 &&
                  selectedCompanyCheckBox === 1 &&
                  selectedCompanyValue.length > 0) ||
                (locationDetails &&
                  locationDetails.length > 0 &&
                  role != "ADMIN"  && usersToInvite === 2 ) ? (
                    <Locationwrapper  background="white" style={{zIndex:"10",position:"relative"}}>
                      <div className="checkBoxWidth" >
                        <span style={{color:"#005c87"}}>
                          <CustomRadioButton
                            onClick={(e) =>
                              this.selectAllLocation(e, locationDetails)
                            }
                            
                            background={"#85c0ea"}
                          >
                            {" "}
                            {locationDetails &&
                              city_location.length === locationDetails.length && (<div></div>)}{" "}
                          </CustomRadioButton>{" "}
                          {locationDetails &&
                          city_location.length === locationDetails.length
                            ? this.props.t("Deselect All")
                            : this.props.t("Select All")}
                        </span>
                      </div>
                      {locationDetails && locationDetails.length > 0
                        ? locationDetails.map((loc, index) => (
                          <div className="checkBoxWidth" key={index}>
                            <span style={{color:"#005c87"}}>
                              <CustomRadioButton
                                onClick={() => this.onChangeLocation(loc.id)}
                                background="#afcbd3"
                                style={{border:"1px solid #649bb3"}}
                              >
                                {" "}
                                {city_location.includes(loc.id) && (
                                  // <div></div>
                                  <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                                )}{" "}
                              </CustomRadioButton>
                              {this.removeCommaFromFirstPlace(loc.location)}
                            </span>
                          </div>
                        ))
                        : null}
                    </Locationwrapper>
                  ) : null}

                {/* Dpartment Listing */}

                {(role === "ADMIN" &&
                  departmentDetails && 
                  departmentDetails?.length > 0 &&
                  selectedCompanyCheckBox === 1 &&
                  selectedCompanyValue.length > 0 && usersToInvite === 1) ||
                (departmentDetails &&
                  departmentDetails?.length > 0 &&
                  role != "ADMIN"  && usersToInvite === 1 ) ? (<Heading>
                    <div className="name" style={{color:"#005c87"}}>
                      {this.props.t("Select departments")}
                      <span className="astric">*</span>
                    </div>
                  </Heading>
                  ) : null}
                {(role === "ADMIN" &&
                usersToInvite === 1 && departmentDetails &&
                  departmentDetails?.length > 0 &&
                  selectedCompanyCheckBox === 1 &&
                  selectedCompanyValue.length > 0) ||
                (departmentDetails &&
                  departmentDetails?.length > 0 &&
                  role != "ADMIN"  && usersToInvite === 1 ) ? (
                    <Locationwrapper  background="white" style={{zIndex:"10",position:"relative"}}>
                      <div className="checkBoxWidth" >
                        <span style={{color:"#005c87"}}>
                          <CustomRadioButton
                            onClick={(e) =>
                              this.selectAllDepartment(e, departmentDetails)
                            }
                            
                            background={"#85c0ea"}
                          >
                            {" "}
                            {departmentDetails &&
                              city_department?.length === departmentDetails?.length && (<div></div>)}{" "}
                          </CustomRadioButton>{" "}
                          {departmentDetails &&
                            city_department.length === departmentDetails?.length
                            ? this.props.t("Deselect All")
                            : this.props.t("Select All")}
                        </span>
                      </div>
                      {departmentDetails && departmentDetails?.length > 0
                        ? departmentDetails.map((dep, index) => (
                          <div className="checkBoxWidth" key={index}>
                            <span style={{color:"#005c87"}}>
                              <CustomRadioButton
                                onClick={() => this.onChangeDepartment(dep.id)}
                                background="#afcbd3"
                                style={{border:"1px solid #649bb3"}}
                              >
                                {" "}
                                {city_department.includes(dep.id) && (
                                  // <div></div>
                                  <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                                )}{" "}
                              </CustomRadioButton>
                              {this.removeCommaFromFirstPlace(dep.department)}
                            </span>
                          </div>
                        ))
                        : null}
                    </Locationwrapper>
                  ) : null}

                {/* Teams Listing */}

                {(role === "ADMIN" &&
                  allTeams && 
                  allTeams.length > 0 &&
                  selectedCompanyCheckBox === 1 &&
                  selectedCompanyValue.length > 0 && usersToInvite === 3) ||
                (allTeams &&
                  allTeams.length > 0 &&
                  role != "ADMIN"  && usersToInvite === 3 ) ? (<Heading>
                    <div className="name" style={{color:"#005c87"}}>
                      {this.props.t("Select teams")}
                      <span className="astric">*</span>
                    </div>
                  </Heading>
                  ) : null}
                {(role === "ADMIN" &&
                usersToInvite === 3 && allTeams &&
                  allTeams.length > 0 &&
                  selectedCompanyCheckBox === 1 &&
                  selectedCompanyValue.length > 0) ||
                (allTeams &&
                  allTeams.length > 0 &&
                  role != "ADMIN"  && usersToInvite === 3 ) ? (
                    <Locationwrapper  background="white" style={{zIndex:"10",position:"relative"}}>
                      <div className="checkBoxWidth" >
                        <span style={{color:"#005c87"}}>
                          <CustomRadioButton
                            onClick={(e) =>
                              this.selectAllTeam(e, allTeams)
                            }
                            
                            background={"#85c0ea"}
                          >
                            {" "}
                            {allTeams &&
                              teams.length === allTeams.length && (<div></div>)}{" "}
                          </CustomRadioButton>{" "}
                          {allTeams &&
                          teams.length === allTeams.length
                            ? this.props.t("Deselect All")
                            : this.props.t("Select All")}
                        </span>
                      </div>
                      {allTeams && allTeams.length > 0
                        ? allTeams.map((team, index) => (
                          <div className="checkBoxWidth" key={index + team}>
                            <span style={{color:"#005c87"}} >
                              <CustomRadioButton
                                onClick={() => this.onChangeTeam(team.id)}
                                background="#afcbd3"
                                style={{border:"1px solid #649bb3"}}
                              >
                                {" "}
                                {teams.includes(team.id) && (
                                  // <div></div>
                                  <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                                )}{" "}
                              </CustomRadioButton>
                              {this.removeCommaFromFirstPlace(team.name)}
                            </span>
                          </div>
                        ))
                        : null}
                    </Locationwrapper>
                  ) : null}

                {(companyInfo?.show_spouse || companyInfo?.show_dependent ||  (locationDetails?.length === 0 && usersToInvite === 2)) && companies?.length > 0 && (role !== "ADMIN" || selectedCompanyCheckBox === 1) && (usersToInvite!==0 && usersToInvite!==3) ? ( <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>) : null}

                {/* Spouse Dependent */}


                {(companyInfo?.show_spouse || companyInfo?.show_dependent ||  (locationDetails?.length === 0 && usersToInvite === 2)) && companies?.length > 0 && (role !== "ADMIN" || selectedCompanyCheckBox === 1) && (usersToInvite!==0 && usersToInvite!==3) ? (
                  <Heading>
                    <div className="name" style={{color:"#005c87"}}>{("Are you also interested in extending an invitation?")}</div>
                  </Heading>) : null}

                {companies?.length > 0 && (role !== "ADMIN" || selectedCompanyCheckBox === 1) && (usersToInvite!==0 && usersToInvite!==3) ? <Locationwrapper  background="white">
                  {companyInfo?.show_spouse ?<div className="checkBoxWidth">
                    <span style={{color:"#005c87"}}>
                      <CustomRadioButton
                        onClick={() => this.selectSpouseDependent("isSpouse")}
                        background="#afcbd3"
                        style={{border:"1px solid #649bb3"}}
                      >
                        {" "}
                        {isSpouse && (
                          // <div></div>
                          <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                        )}{" "}
                      </CustomRadioButton>
                      {this.props.t("Spouse")}
                    </span>
                  </div>:null}
                  {companyInfo?.show_dependent ?<div className="checkBoxWidth">
                    <span>
                      <CustomRadioButton
                        onClick={() =>
                          this.selectSpouseDependent("isDependent")
                        }
                      >
                        {" "}
                        {isDependent && (
                          // <div></div>
                          <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                        )}{" "}
                      </CustomRadioButton>
                      {this.props.t("Dependent")}
                    </span>
                  </div>:null}
                  {locationDetails?.length === 0 && usersToInvite === 2 &&  (
                    <div className="checkBoxWidth">
                      <span>
                        <CustomRadioButton
                          onClick={() =>
                            this.selectSpouseDependent("isEmployee")
                          }
                        >
                          {" "}
                          {isEmployee && (
                            // <div></div>
                            <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                          )}{" "}
                        </CustomRadioButton>
                        {this.props.t("Employee")}
                      </span>{" "}
                    </div>
                  )}
                </Locationwrapper>:null}


                {/* Spouse Dependent */}
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  setDatePickerWrapper = (node) => {
    this.datePickerWrapper = node;
  };

  changeDate = (e, stateName) => {
    const { showDateTimeInput } = this.state;
    if (showDateTimeInput === 'date') {
      this.setState({
        date: dayjs(e._d).format('MM/DD/YYYY'),
        time: dayjs(e._d).format('hh:mm a'),
        endDate: dayjs(e._d).format('MM/DD/YYYY'),
        endTime: '11:59 pm',
        openDateTimePicker: '',
      });
    } else if (stateName.includes('ate')) {
      if(stateName === 'date'){
        this.setState({
          openDateTimePicker: '',
          [stateName]: dayjs(e._d).format('MM/DD/YYYY'),
          endDate:dayjs(e._d).format('MM/DD/YYYY'),
        });
      }else {
        this.setState({
          openDateTimePicker: '',
          [stateName]: dayjs(e._d).format('MM/DD/YYYY'),
        });

      }
    } else {
      this.setState({
        [stateName]: dayjs(e._d).format('hh:mm a'),
        openDateTimePicker: stateName
      });
    }
  };

  showDatePicker = (value) => {
    if (this.state.openDateTimePicker === value) {
      this.setState({
        openDateTimePicker: ''
      });
    } else {
      this.setState({
        openDateTimePicker: value
      });
    }
  };

  onChangeEmail = (key) => {
    const { emailOption } = this.state;
    emailOption[key] = emailOption[key] === 0 ? 1 : 0;
    this.setState({ emailOption: emailOption });
  }

  checkBase64(image) {
    if (image) {
      if (image.includes('base64')) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  onSubmit = () => {
    // e.preventDefault();
    const { title, date, description, editImage, endDate, activityType, challengePointValue, challengeMode, weeklyIncrement,
      survivorAverage, companies, isSpouse, isDependent, challengeLength, survivorChallenge, groupChallenge, imageUpdated, emailOption, surveyId, selectedOption, dailyGoal, selectedDailyGoalOption, teams, imageName, city_location, city_department,  usersToInvite } = this.state;
   
    const {  createLibrary, activityChallengeById, editLibrary, saveChallengeData } = this.props;
    let survivorEndDate = dayjs(dayjs(date).add((challengeLength * 7) - 1, 'days')).format('MM/DD/YYYY');
    const challengeType = survivorChallenge ? 'survivor' : groupChallenge ? 'new_group' : 'group';
    const challenge = {
      title,
      companies: this.props.role==="ADMIN"?companies:this.props.userCompany && [this.props.userCompany.id],
      body: description,
      image: editImage,
      event_type: activityType === 'Total Group Steps' ? 'Total Steps' : activityType === 'Total Group Calories' ? 'Total Calories' : activityType === 'Total Group Distance'? 'Total Distance' : activityType,
      end_date: createLibrary || editLibrary ? null : (survivorChallenge) ? dayjs(survivorEndDate).format('YYYY-MM-DD') + ' ' + dayjs('23:59:59 PM', "hh:mm:ss p").format("HH:mm:ss") : dayjs(endDate).format('YYYY-MM-DD') + ' ' + dayjs('23:59:59 PM', "hh:mm:ss p").format("HH:mm:ss"),
      start_date: createLibrary || editLibrary ? null : dayjs(date).format('YYYY-MM-DD') + ' ' + dayjs('12:00:00 AM', "hh:mm:ss a").format("HH:mm:ss"),
      survivor_average: survivorAverage,
      weekly_increment: survivorChallenge ? weeklyIncrement : null,
      challenge_point: challengePointValue,
      challenge_mode: survivorChallenge ? challengeMode : '',
      challenge_type: challengeType,
      isSurvivorChallenge: survivorChallenge,
      isGroupChallenge: groupChallenge,
      ...emailOption,
      survey_id: selectedOption === 1 ? null : surveyId,
      daily_goal: selectedDailyGoalOption === 1 ? null : dailyGoal,
      is_team_challenge:teams.length>0?1:0, 
      teams:teams,
      company_city_states :city_location,
      company_departments: city_department,
      challenge_for_spouse: isSpouse ? 1 : 0,
      challenge_for_dependent: isDependent ? 1 : 0,
      is_for_employee: 1,
      time_zone: TimeZone
    };
    // activityChallengeById ? challenge.convert_image = !this.checkBase64(editImage) : null;
    if(activityChallengeById){
      challenge.convert_image = !this.checkBase64(editImage)
    }
    if (editLibrary) {
      challenge.id = this.props.survivorChallengeData['id'];
      challenge.imageUpdated = imageUpdated
    }

    if(usersToInvite === 0){
      challenge.company_departments = [];
      challenge.company_city_states = [];
      challenge.teams = [];
    }else if(usersToInvite === 1){
      challenge.company_departments = city_department;
      challenge.company_city_states = [];
      challenge.teams = [];
    }else if(usersToInvite === 2){
      challenge.company_departments = [];
      challenge.company_city_states = city_location;
      challenge.teams = [];
    }else if(usersToInvite === 3){
      challenge.teams = teams;
      challenge.is_team_event=1;
      challenge.company_departments = [];
      challenge.company_city_states = [];
    }


    if(usersToInvite===0){
      challenge.challenge_for_spouse =1;
      challenge.challenge_for_dependent = 1;
    }
    saveChallengeData({ ...challenge, imageName });
    this.postChallengeData(challenge);
  };

  postChallengeData = async(payload)=>{

    this.setState({ buttonStatus: true });

    const{history} = this.props;
    try{
      const res = await fetchApi(CreateChallenge, "POST",payload);

      if(res.status === "failed"){
        toast.error(res.message);
        this.setState({ buttonStatus: false });
      }else{
        toast.success(res.data[0]);
        history.push({pathname:history.location.pathname.includes("/company/challenges/")?'/company/challenges/categories':'/challenges/categories'});
        this.setState({ buttonStatus: false });
      }

    }catch(error){
      toast.error(error);
      this.setState({ buttonStatus: false });

    }
  }

  scheduleDateHideCalendar = () =>{
    this.setState({
      scheduleStartDateActive: false
    })
  }

  scheduleEndDateHideCalendar = () =>{
    this.setState({
      scheduleEndDateActive: false
    })
  }

  onChanegeButton = (value) => {
    this.setState({
      launchEndDate: dayjs(new Date(value))
    })
  }

  onChanegeScheduleButton = (value) => {
    this.setState({
                            
      scheduleStartDate: dayjs(new Date(value))
    })
  }

  onChanegeScheduleEndButton = (value) => {
    this.setState({
      scheduleEndDate: dayjs(new Date(value))
    })
  }

  stepFourForm = () => {
    const {
      step,
      scheduleChallenge,
      date,
      endDate,
      emailOption,
      challengeLength
    } = this.state;
    const {t, survivorChallengeData} = this.props;
    let emailOptionKeys = Object.keys(emailOption);
    const survivorChallengeLengthDropdown = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container height="100%" stepBackground="#005C87" color="#005C87" paddingForm>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} heading={this.stepsHeadingEvent}/>
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">{this.props.t("When would you like to launch your challenge?")}</div>
              </div>
              <div className="formBody">
                <StyledLabel color="#005C87">
                  {t("Would you like to:")}{<span className="imp">*</span>}
                </StyledLabel>
                {<div className="radioButtonDiv">
                  <span onClick={() => this.dateRadioButton("scheduleChallenge",0)}>
                    <RadioButton
                      id="1"
                      handler={this.dateRadioButton}
                      value={0}
                      isChecked={scheduleChallenge === 0}
                      label={this.props.t("Launch now")}
                      challengeCreate={true}
                    />
                  </span>
                  <span onClick={scheduleChallenge===1?null: () => this.dateRadioButton("scheduleChallenge",1)}>
                    <RadioButton
                      id="2"
                      handler={ scheduleChallenge===1?null:this.dateRadioButton}
                      value={1}
                      isChecked={scheduleChallenge === 1}
                      label={this.props.t("Schedule  for later")}
                      challengeCreate={true}
                    />
                  </span>
                </div>}
                {scheduleChallenge === 0 ?
                  <div className="dateCard">
                    <DateCalendarWrapper>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MUICalendarWrapper>
                          <DatePicker
                            label="Select start date"
                            value={dayjs(date)}
                            onChange={(value) => {
                              this.onChangeDateV1(value?.$d,"date");
                            }}
                            minDate={dayjs()}
                            maxDate={endDate}
                            onClose={this.scheduleDateHideCalendar}
                            sx={{ width: "416px", color: "#005C8799 !important", borderColor: "#005C87" }}
                            slots={{ openPickerIcon: CalendarIconDisabled }}
                            disabled={true}
                          />
                        </MUICalendarWrapper>
                      </LocalizationProvider>
                    </DateCalendarWrapper> 
                    <div className="dashIcon">{DashIcon()}</div>
                    {survivorChallengeData.challenge_type=="survivor"?
                      <TitleContainer  height="auto" padding="0">
                        <CommonDropdown
                          tileContainerStyle={{  height:"55px", width: "100%", padding: "0px 0px 0px 0px" }}
                          dropdownStyle={{ top: "90px" }}
                          isRequired={true}
                          height={"56px"}
                          dropdownOpen={this.state.weekArrow}
                          placeholder={t("Select weeks to end")}
                          title={challengeLength && `${challengeLength} ${t("Weeks")}`}
                          id="dropdown-recurring"
                          onClick={() => this.setState({ weekArrow: !this.state.weekArrow })}
                          data={survivorChallengeLengthDropdown}
                          onSelect={this.onSelectWeeks}
                          active={challengeLength}
                          onSelectParameter={["value"]}
                          itemValue={false}
                          valueString={"Weeks"}
                          t={t}
                        />
                      </TitleContainer>:
                      <DateCalendarWrapper>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MUICalendarWrapper color={endDate?"#005C87 !important":"#005C874D"}>
                            <DatePicker
                              label="Select end date"
                              sx={{width:"416px"}}
                              onChange={(value) => {
                                this.onChangeDateV1(value?.$d, "endDate");
                              }}
                              value={endDate}
                              minDate={dayjs(date)}
                              slots={{openPickerIcon:CalendarIcon}}
                              disablePast={true}
                              views={['year', 'month', 'day']}
                            />
                          </MUICalendarWrapper>
                        </LocalizationProvider>
                         
                      </DateCalendarWrapper>
                    }
                  </div>:scheduleChallenge === 1&&
                  <div className="dateCard">
                    <DateCalendarWrapper>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MUICalendarWrapper>
                          <DatePicker
                            label="Select start date"
                            value={date}
                            onChange={(value) => {
                              this.onChangeDateV1(value?.$d,"date");
                            }}
                            minDate={dayjs()}
                            maxDate={endDate}
                            onClose={this.scheduleDateHideCalendar}
                            sx={{ width: "416px", color: "#005C87", borderColor: "#005C87" }}
                            slots={{ openPickerIcon: CalendarIcon }}
                          />
                        </MUICalendarWrapper>
                      </LocalizationProvider>
                    </DateCalendarWrapper> 
                    <div className="dashIcon">{DashIcon()}</div>
                    {survivorChallengeData.challenge_type=="survivor"?
                      <TitleContainer  height="auto" padding="0">
                        <CommonDropdown
                          tileContainerStyle={{  height:"55px", width: "100%", padding: "0px 0px 0px 0px" }}
                          dropdownStyle={{ top: "90px" }}
                          isRequired={true}
                          height={"56px"}
                          dropdownOpen={this.state.weekArrow}
                          placeholder={t("Select weeks to end")}
                          title={challengeLength && `${challengeLength} ${t("Weeks")}`}
                          id="dropdown-recurring"
                          onClick={() => this.setState({ weekArrow: !this.state.weekArrow })}
                          data={survivorChallengeLengthDropdown}
                          onSelect={this.onSelectWeeks}
                          active={challengeLength}
                          onSelectParameter={["value"]}
                          itemValue={false}
                          valueString={"Weeks"}
                          t={t}
                        />
                      </TitleContainer>:
                      <DateCalendarWrapper>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MUICalendarWrapper color={endDate?"#005C87 !important":"#005C874D"}>
                            <DatePicker
                              label="Select end date"
                              sx={{width:"416px"}}
                              onChange={(value) => {
                                this.onChangeDateV1(value?.$d, "endDate");
                              }}
                              value={endDate}
                              minDate={dayjs(date)}
                              slots={{openPickerIcon:CalendarIcon}}
                              disablePast={true}
                              views={['year', 'month', 'day']}
                            />
                          </MUICalendarWrapper>
                        </LocalizationProvider>
                         
                      </DateCalendarWrapper>}
                  </div>}
                {scheduleChallenge === 0||scheduleChallenge === 1?<StyledLabel color="#005C87">
                  {this.props.t("Email Notifications: Select email option for this Challenge")}
                </StyledLabel>:null}
                {scheduleChallenge === 0||scheduleChallenge === 1?<Locationwrapper
                  border
                  background="none" 
                  marginTop="0"
                  padding="0"
                  isWidth={"27.33%"}
                >
                  {emailOptionKeys.map((item, index) => (
                    <div className="checkBoxWidth" key={index}>
                      <span>
                        <CustomRadioButton
                          checked={emailOption[item] === 1}
                          onClick={() => this.onChangeEmail(item)}
                        >
                          {emailOption[item] === 1 && (
                            <img src={ImageUrl+"/ChallengeDetailsScreen/updatedCheckmark.svg"}></img>
                          )}
                        </CustomRadioButton>
                        {t("Challenge")}
                        {item == "launch_challenge_email"? t(" Creation"): item == "challenge_reminder_email"? t(" Reminder"): t(" Completion")}
                      </span>
                    </div>
                  ))}
                </Locationwrapper>:null}
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  DropdownIndicator = () => (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 10C12 10.3585 11.8559 10.7169 11.5684 10.9901L2.51663 19.5897C1.94083 20.1368 1.00725 20.1368 0.431681 19.5897C-0.143894 19.0429 -0.143894 18.1561 0.431681 17.609L8.44122 10L0.43196 2.39098C-0.143614 1.84394 -0.143614 0.957264 0.43196 0.410484C1.00753 -0.136826 1.94111 -0.136826 2.51691 0.410484L11.5687 9.00992C11.8563 9.28333 12 9.64172 12 10Z"
        fill="#0D4270"
      />
    </svg>
  );

  toggleMenu = () => {
    this.setState((prevState) => ({
      menuIsOpen: !prevState.menuIsOpen,
    }));
  };

  updateStep = (value) => {
    this.setState({ step: value, buttonClickScrollTop: true, buttonStatus:false });
  };

  showLoaderApiCall = (value) => {
    this.setState({ showLoader:value });
  };

  render() {
    const { step, buttonStatus, title, imgSrc, description, activityType, city_location, teams, endDate, companies, showLoader, challengeLength, isSpouse, isDependent, isEmployee, selectedDailyGoalOption,dailyGoal,usersToInvite,city_department } = this.state;
    const {locationDetails, role, companyDetails} = this.props;
    if (showLoader) {
      return <Waiting />
    }
    return (
      <React.Fragment>
        {step === 1?this.stepOneForm(): step === 2? this.stepTwoForm(): step === 3 ? this.stepThreeForm(): step === 4 && this.stepFourForm()}
        {step === 1 ? (
          <ButtonContainer>
            <Main>
              <CommonButton
                btnType={"squareIcon"}
                onClick={() => this.updateStep(2)}
                title={this.props.t("Next")}
                styles={{color:"#007AB1" }}
                nextIcon={NextArrows("white")}
                disabled={!title||!imgSrc|| !description}
              />
            </Main>
          </ButtonContainer>
        ) : step === 2 ? (
          <ButtonContainer>
            <div className="wrapper">
              <CommonButton
                btnType={"squareIcon"}
                styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center"  }}
                onClick={() => this.updateStep(1)}
                title={this.props.t("Go Back")}
                icon={Arrows()}
              />
              <CommonButton
                btnType={"squareIcon"}
                disabled={!activityType || (selectedDailyGoalOption == 0 && !dailyGoal )}
                styles={{marginAuto:"auto", color:"#007AB1" }}
                onClick={() => this.updateStep(3)}
                title={this.props.t("Next")}
                nextIcon={NextArrows("white")}
              />
            </div>
          </ButtonContainer>
        ) : (
          step === 3 ? (
            <ButtonContainer>
              <div className="wrapper">
                <CommonButton
                  btnType={"squareIcon"}
                  styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center"  }}
                  onClick={() => this.updateStep(2)}
                  title={this.props.t("Go Back")}
                  icon={Arrows()}
                />
                <CommonButton
                  btnType={"squareIcon"}
                  disabled={
                    ( !companies.length > 0 && role === "ADMIN") ||
                  (role !== "ADMIN" &&(!city_location.length > 0 &&!_.isEmpty(locationDetails) && usersToInvite === 2) || (role !== "ADMIN" && !isEmployee && !isDependent&& !isSpouse && _.isEmpty(locationDetails) && companyDetails && companyDetails['spouse_or_dependent']) || companies.length === 0 || (usersToInvite === 2 && !city_location.length > 0) || (usersToInvite === 1 && !city_department.length > 0) || (usersToInvite === 3 && !teams.length > 0))
                  }
                  styles={{marginAuto:"auto",color:"#007AB1" }}
                  onClick={() => this.updateStep(4)}
                  title={this.props.t("Next")}
                  nextIcon={NextArrows("white")}
                />
              </div>
            </ButtonContainer>
          ):step === 4 &&
          <ButtonContainer>
            <div className="wrapper">
              <CommonButton
                btnType={"squareIcon"}
                styles={{color:"white", textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center"  }}
                onClick={() => this.updateStep(3)}
                title={this.props.t("Go Back")}
                icon={Arrows()}
              />
              <CommonButton
                btnType={"squareIcon"}
                disabled={!endDate&& challengeLength==null||buttonStatus}
                styles={{color:"#007AB1", marginAuto:'auto', width:"230px" }}
                onClick={()=>!buttonStatus? this.onSubmit():""}
                title={this.props.t("Launch Challenge")}
                nextIcon={LaunchEventIcon()}
              />
            </div>
          </ButtonContainer>
        )}
      </React.Fragment>
    );
  }
}

LaunchActivityChallenge.propTypes = {
  isSurvivorChallenge: PropTypes.bool.isRequired,
  createChallenge: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onHide: PropTypes.func,
  MandatoryFields: PropTypes.array.isRequired,
  survivorChallengeData: PropTypes.object,
  saveChallengeData: PropTypes.func.isRequired,
  allCompanies: PropTypes.array,
  fetchAllCompanies: PropTypes.func.isRequired,
  role: PropTypes.string,
  fetchCompanyForUser: PropTypes.func.isRequired,
  userCompany: PropTypes.object,
  locationDetails: PropTypes.array,
  companyDetails: PropTypes.object,
  allowChallengeEventForSpouseDependent: PropTypes.func,
  isGroupChallenge: PropTypes.bool,
  activityChallengeById: PropTypes.bool,
  createActivityChallenge: PropTypes.func,
  createLibrary: PropTypes.bool,
  editChallengeLibrary: PropTypes.func,
  editLibrary: PropTypes.bool,
  userPermissions: PropTypes.array,
  surveyLibraries: PropTypes.array,
  getSurveyLibraries: PropTypes.func,
  t: PropTypes.func,
  fetchCompanyTeamUsers:PropTypes.func,
  challengeDetails: PropTypes.array,
  getChallengeDetails: PropTypes.func,
  getCompanyLocation: PropTypes.func,
  user: PropTypes.object,
  departmentDetails: PropTypes.array,
  getCompanyDepartment: PropTypes.func,
  waitForCompanyDepartment: PropTypes.bool
};

const mapStateToProps = (state) => ({
  allCompanies: state.challenges.allCompanies,
  userCompany: state.profileData.userCompany,
  locationDetails: state.register.locationDetails,
  companyDetails: state.profileData.companyDetails,
  userPermissions: state.profileData.userPermissions,
  surveyLibraries: state.surveys.surveyLibraries,
  challengeDetails: state.challenges.challengeDetails,
  user: state.profileData.user,
  departmentDetails: state.register.departmentDetails,
  waitForCompanyDepartment: state.register.waitForCompanyDepartment
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllCompanies: () => dispatch(getAllCompanies()),
  saveChallengeData: (data) => dispatch(saveChallengeData(data)),
  fetchCompanyForUser: () => dispatch(getUserCompanyDetails()),
  allowChallengeEventForSpouseDependent: () => dispatch(allowChallengeEventForSpouseDependent),
  editChallengeLibrary: (challenge) => dispatch(editChallengeLibrary(challenge)),
  fetchCompanyTeamUsers: (companyId) => dispatch(fetchCompanyTeamUsers(companyId)),
  createActivityChallenge: (challenge) => dispatch(createActivityChallengeV2(challenge)),
  getChallengeDetails: (id) => dispatch(getActivityChallengeById(id)),
  getCompanyLocation: (id) => dispatch(getCompanyLocation(id)),
  getCompanyDepartment: (id) => dispatch(getCompanyDepartment(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LaunchActivityChallenge));
